import React, { useEffect, useState } from 'react'
// import './SocialBottom.scss'
import TrackVisibility from 'react-on-screen'

// let lastId = 0
import dynamic from 'next/dynamic'

const Footer = dynamic(() => import('./Footer'))

const FooterWithSocialTracked = React.memo(function FooterWithSocialTracked({
    isVisible,
    i18n,
    kbLangKey,
    sectionKey,
    locale
}) {
    const [showFooter, setShowFooter] = useState(false)

    useEffect(() => {
        if (isVisible && !showFooter) {
            setShowFooter(true)
        }
    }, [isVisible, showFooter])

    return (
        <>
            &nbsp;
            {/* <Suspense fallback={<Loader />}> */}
            {showFooter && <Footer {...{ locale, i18n, kbLangKey, sectionKey, showFooter }} />}
            {/* </Suspense> */}
        </>
    )
})

const FooterWithSocial = React.memo((props) => (
    <TrackVisibility partialVisibility once>
        <FooterWithSocialTracked {...props} />
    </TrackVisibility>
))
FooterWithSocial.displayName = 'FooterWithSocial'

export default FooterWithSocial
