import React, { useEffect } from 'react'
import TrackVisibility from 'react-on-screen'

const load = require('load-script')

const noads = typeof window !== 'undefined' && window.location.search.search('noads') > 0

if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const handler = () => {
        console.log('updateKey ================loaded once')
        load('//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js')
        window.removeEventListener('load', handler, false)
    }
    if (document.readyState === 'complete') {
        handler()
    } else {
        window.addEventListener('load', handler)
    }
}

export const AdsenseAdPure = React.memo(function AdsenseAdPure({
    adSlot,
    adFormat,
    responsive = true,
    className = '',
    updateKey,
    ...props
}) {
    useEffect(() => {
        console.log('adsense updateKey:', updateKey)
        ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    }, [updateKey])

    return (
        <>
            <style jsx>
                {`
                    .adsbygoogle {
                        display: block;
                    }
                    .upper_side_ad,
                    .lower_side_ad {
                        width: 300px;
                        height: 250px;
                    }
                    .lower_side_ad {
                        margin-top: 5px;
                    }
                    .buttom_rect {
                        margin: 0 auto;
                        display: inline-block;
                        width: 336px;
                        height: 280px;
                    }

                    @media (max-width: 1075px) {
                        .upper_side_ad {
                            display: flex;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .upper_banner_ad,
                    .below_ad_1075 {
                        display: flex;
                        width: 100%;
                        height: 100%;
                    }

                    ins.adsbygoogle {
                        border: 1px solid #2974b800;
                    }

                    ins.adsbygoogle.dev {
                        border: 1px solid #2974ba;
                    }
                `}
            </style>

            <ins
                key={updateKey}
                className={'adsbygoogle ' + className + (process.env.NODE_ENV !== 'production' ? ' dev' : '')}
                data-ad-client='ca-pub-0070744872185334'
                data-ad-slot={adSlot}
                data-ad-format={adFormat}
                data-full-width-responsive={responsive}
                data-adsbygoogle-status={false}
                {...props}
            ></ins>
        </>
    )
})

export const AdsenseAdVisible = ({ isVisible = false, ...props }) =>
    isVisible && !noads ? <AdsenseAdPure {...props} /> : null

const AdsenseAd = React.memo(({ parentClassName, ...props }) => (
    <>
        <style jsx global>
            {`
                .parent-side-ad {
                    display: flex;
                    flex-grow: 1;
                    max-width: 300px;
                    flex-direction: column;
                }
                .parent-side-ad {
                    display: flex;
                }

                .parent_upper_banner {
                    width: 728px;
                    height: 90px;
                }

                @media (max-width: 1075px) {
                    .parent-ad-1075 {
                        display: flex;
                        justify-content: space-around;
                    }
                    .parent_upper_banner,
                    .parent-side-ad {
                        display: none;
                    }
                }
                @media (min-width: 728px) and (max-width: 1075px) {
                    .parent-ad-1075 {
                        width: 728px;
                        height: 92px;
                        margin: 0 auto;
                    }
                }
                @media (min-width: 605px) and (max-width: 728px) {
                    .parent-ad-1075 {
                        width: 100vw;
                        height: 280px;
                    }
                }
                @media (max-width: 605px) {
                    .parent-ad-1075 {
                        width: 100vw;
                        height: 100vw;
                    }
                }
            `}
        </style>
        <TrackVisibility partialVisibility once className={parentClassName}>
            <AdsenseAdVisible {...props} />
        </TrackVisibility>
    </>
))
AdsenseAd.displayName = 'AdsenseAd'

export default AdsenseAd
