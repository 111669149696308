import React, { useEffect, useCallback } from 'react'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import dynamic from 'next/dynamic'
import ActionButton from './ActionButton'

const StyledMenu = dynamic(() => import('./StyledMenu'))

const DropButtonMenu = React.memo(function DropButtonMenu({
    children,
    resetKey,
    generateOptionsDynamically,
    text,
    Icon,
    onClick,
    variant = 'contained',
    className,
    which,
    onItemClick,
    menuItemProps,
    i18n,
    dropdown = true,
    ...props
}) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [options, setOptions] = React.useState([])

    const handleClick = useCallback(
        (event) => {
            // window.dataLayer.push({
            //     event: 'button_click_event', // Not necessarily needed if this loads before GTM is initialized.
            //     button_name_var: 'button: ' + eventKey
            // })
            setOptions(generateOptionsDynamically ? [children, ...generateOptionsDynamically()] : children)
            if (onClick) {
                onClick(event)
            } else {
                setAnchorEl(event.currentTarget)
            }
        },
        [children, generateOptionsDynamically, onClick]
    )

    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    useEffect(() => {
        setAnchorEl(null)
    }, [resetKey])

    return (
        <>
            <ActionButton
                className={className}
                variant={variant}
                color='inherit'
                onClick={handleClick}
                startIcon={Icon ? <Icon /> : undefined}
                endIcon={dropdown && <ArrowDropDownIcon />}
                {...props}
            >
                {text}
            </ActionButton>
            {(children || which) && anchorEl ? (
                // <Suspense fallback={<Loader style={{ top: 0 }} />}>
                <StyledMenu
                    id={'customized-menu-' + text.toLowerCase()}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    which={which}
                    onItemClick={onItemClick}
                    menuItemProps={menuItemProps}
                    i18n={i18n}
                    dropdown={dropdown}
                >
                    {options}
                </StyledMenu>
            ) : // </Suspense>
            null}
        </>
    )
})

export default DropButtonMenu
