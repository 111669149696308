export const toTitleCase = (s) =>
    s
        ? s.replace(/[^\s-]*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
          })
        : ''
export const toTitleCaseOnlyFirst = (s) =>
    s
        ? s.replace(/[^\f\n\r\t\v\u00A0\u2028\u2029/]*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
          })
        : ''
export const toTitleCaseSlugs = (s) =>
    s
        ? s.replace(/[^\f\n\r\t\v\u00A0\u2028\u2029_\-/]*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
          })
        : ''

export const urlAdjustements = (locale, url) => {
    !url && (!locale || locale === 'en') && (url = '/')
    !url && locale && locale !== 'en' && (url = '/' + locale)
    return url
}

export const slugAdjustements = (locale, slug) => {
    slug && locale && (locale === 'en' || locale === 'fr' || locale === 'de') && (slug = toTitleCaseSlugs(slug))
    slug && locale && (locale === 'es' || locale === 'ru') && (slug = toTitleCaseOnlyFirst(slug)) // spanish, russian only first is capital
    return slug
}

export const titleAdjustements = (locale, text) => {
    text && locale && (locale === 'en' || locale === 'fr' || locale === 'de') && (text = toTitleCase(text))
    text && locale && (locale === 'es' || locale === 'ru') && (text = toTitleCaseOnlyFirst(text))
    return text
}

const Locales = {
    // data: ["en", "fr"],
    data: ['en', 'fr', 'de', 'ar', 'es', 'he', 'ru', 'hi', 'ko', 'fa', 'ur'], // locales to include
    // NOTICE also on next.config.js

    flag: {
        en: 'gb',
        fr: 'fr',
        de: 'de',
        ar: 'sa',
        es: 'es',
        he: 'il',
        ru: 'ru',
        fa: 'ir',
        ur: 'pk',
        hi: 'in',
        ko: 'kr'
    },

    getForPath: () => Locales.data.filter((x) => x !== 'en').join('|') // i.e 'fr|de|he'

    // pathLanguageDetector: {
    //     name: 'g2hPathDetector',

    //     lookup(req) {
    //         //res, options
    //         let locale = 'en'
    //         // if (typeof window === 'undefined' || typeof req === 'undefined') return locale

    //         const url = typeof window !== 'undefined' ? window.location.pathname : req.url
    //         const match = matchPath(url, {
    //             path: `/:locale(${Locales.getForPath()}|Clavier.*|Teclado.*|${encodeURI('מקלדת')}.*|${encodeURI(
    //                 'لوحة'
    //             )}.*|.*Tastatur|.*${encodeURI('клавиатура')}|.*${encodeURI('키보드')}|.*${encodeURI(
    //                 'بورڈ'
    //             )}|${encodeURI('صفحه')}.*|.*${encodeURI('कीबोर्ड')}|nocache)?`,
    //             exact: false,
    //             strict: false,
    //             sensitive: false
    //         })
    //         locale = match.params.locale
    //         !locale && (locale = 'en')

    //         locale.length > 2 &&
    //             ((locale.startsWith('Clavier') && (locale = 'fr')) ||
    //                 (locale.startsWith('Teclado') && (locale = 'es')) ||
    //                 (locale.startsWith(encodeURI('מקלדת')) && (locale = 'he')) ||
    //                 (locale.startsWith(encodeURI('لوحة')) && (locale = 'ar')) ||
    //                 (locale.endsWith('Tastatur') && (locale = 'de')) ||
    //                 (locale.endsWith(encodeURI('키보드')) && (locale = 'ko')) ||
    //                 (locale.endsWith(encodeURI('कीबोर्ड')) && (locale = 'hi')) ||
    //                 (locale.startsWith(encodeURI('صفحه')) && (locale = 'fa')) ||
    //                 (locale.endsWith(encodeURI('بورڈ')) && (locale = 'ur')) ||
    //                 (locale.endsWith(encodeURI('клавиатура')) && (locale = 'ru')))
    //         // console.log('detector', locale, req.url)
    //         return locale
    //     }
    // }
}
// let i18next = {language: 'en'}
// export const setI18next = i18n => i18next = i18n

export default Locales
