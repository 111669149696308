import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(0.5),
        direction: 'ltr',
        textTransform: ({ textTransform }) => textTransform,
        padding: '6px 9px 6px 12px',
        fontWeight: 'bold',
        color: ({ color }) => color || undefined,
        backgroundColor: ({ bgColor }) => bgColor + ' !important',
        '&:hover': {
            filter: 'brightness(122%)'
        },
        wordWrap: 'normal',
        [theme.breakpoints.down('xs')]: {
            margin: '6px 2px',
            padding: '6px 9px',
            fontSize: '12px'
        }
    }
}))

const ActionButton = React.memo(function DropButtonMenu({
    children,
    onClick,
    variant = 'contained',
    className,
    startIcon,
    endIcon,
    ...props
}) {
    const classes = useStyles(props)

    return (
        <>
            <Button
                className={className || classes.button}
                variant={variant}
                color='inherit'
                onClick={onClick}
                startIcon={startIcon}
                endIcon={endIcon}
            >
                {children}
            </Button>
        </>
    )
})

export default ActionButton
