import IconButton from '@material-ui/core/IconButton'

import MenuIcon from '@material-ui/icons/Menu'
import React, { useCallback } from 'react'
import Link from 'next/link'
import Image from 'next/image'

import { keyboardLanguages, sitePath } from '../StaticData/SiteData'
import { AddThisShareMenuButton } from './AddThis'
import DropButtonMenu from './DropButtonMenu'
import LogoBreadcrumbs from './DenseAppBar/LogoBreadcrumbs'

import KeyboardIcon from '@material-ui/icons/Keyboard'
import { LazyMenu } from './LazyLoad'

import { flags } from './Footer'

const load = require('load-script')

const DenseAppBar = React.memo(function DenseAppBar({ locale, i18n, t, kbLangKey, sectionKey }) {
    const handleFeedbackClick = useCallback(() => {
        const feedbackLang = locale ? locale : 'en'
        window.UserVoice = window.UserVoice || []
        window.UserVoice.push(['set', 'locale', feedbackLang])
        window.UserVoice.push([
            'identify',
            {
                language: feedbackLang
            }
        ])
        // window.UserVoice.push(['addTrigger', '#contact_a', { mode: 'contact' }]);
        window.UserVoice.push(['autoprompt', {}])
        load('//widget.uservoice.com/tMhknfhY1IuA7rrlVQ8g.js', () => {
            window.UserVoice.push([
                'set',
                {
                    accent_color: '#6aba2e',
                    trigger_color: 'white',
                    trigger_background_color: '#6aba2e',
                    position: i18n.dir() === 'rtl' ? 'top-left' : 'top-right'
                }
            ])
            window.UserVoice.show()
        })
    }, [i18n, locale])

    const menuOptions = [
        kbLangKey ? (
            <DropButtonMenu
                key='5'
                text={keyboardLanguages.keyToLocaleLanguageName(kbLangKey, i18n)}
                variant='text'
                textTransform={'none'}
                // bgColor='#3480fc'
                Icon={KeyboardIcon}
                i18n={i18n}
                resetKey={kbLangKey + sectionKey}
                eventKey='appbar_kblang'
                color='#bfbfbf'
                which={'change_language'}
            />
        ) : undefined,
        <DropButtonMenu
            key='6'
            text={keyboardLanguages.data[i18n.language].name_native}
            resetKey={i18n.language}
            textTransform={'none'}
            i18n={i18n}
            variant='text'
            // bgColor='#3480fc'
            Icon={useCallback(
                () => (
                    <Image
                        alt={keyboardLanguages.data[i18n.language].name_native}
                        width='20'
                        height='20'
                        src={'/images/flags/flags-iso/flat/24/' + flags[i18n.language] + '.png'}
                        priority={true}
                    />
                ),
                [i18n.language]
            )}
            eventKey='appbar_locale'
            color='#bfbfbf'
            menuItemProps={{ sectionKey, kbLangKey }}
            which={'site_language'}
        />,

        <AddThisShareMenuButton key='7'>{t('UI.(section)Share')}</AddThisShareMenuButton>,
        <DropButtonMenu
            text={t('UI.Feedback')}
            key='8'
            i18n={i18n}
            onClick={handleFeedbackClick}
            eventKey='feedback'
            color='#bfbfbf'
            variant='text'
            dropdown={false}
            textTransform={'none'}
        />
    ]

    return (
        <div className={'DenseAppBar'}>
            <style jsx>{`
                div {
                    direction: ${i18n.dir()};
                }
                .DenseAppBar {
                    background-color: #1b1b1b;
                    z-index: 1100;
                    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
                }

                .UpperLeftLogo {
                    color: #bfbfbf;
                    text-align: left;
                    display: flex;
                    margin-top: 4px;
                    margin-left: 4px;
                    align-items: center;
                    justify-content: left;
                    text-decoration: none;
                }
                .Bar {
                    display: flex;
                    align-items: center;
                    max-width: 1440px;
                    margin: 0 auto;
                    padding: 0 10px;
                }
                .mobileMenuButton {
                    margin-left: -18;
                    margin-right: 10;
                    right: 0px;
                }
                .flexgrow1 {
                    flex-grow: 1;
                }
                .LargeMenu {
                    display: none !important;
                }
                .CollapsedMenu {
                    display: none !important;
                    color: white;
                }
                @media (max-width: 960px) {
                    .DenseAppBar {
                        position: fixed;
                        right: 0;
                        left: 0;
                        top: 0;
                    }
                    .CollapsedMenu {
                        display: flex !important;
                    }
                }
                @media (min-width: 961px) {
                    .LargeMenu {
                        display: flex !important;
                    }
                }
            `}</style>
            <div className={'Bar'}>
                {kbLangKey && <LogoBreadcrumbs t={t} i18n={i18n} kbLangKey={kbLangKey} sectionKey={sectionKey} />}

                {!kbLangKey && (
                    <Link href={sitePath.keysToPath('', '', i18n)} aria-label={t('UI.Welcome To Gate2Homecom')}>
                        <a className={'UpperLeftLogo'}>{t('UI.Welcome To Gate2Homecom')}</a>
                    </Link>
                )}

                <div className={'flexgrow1'} />

                <div className={'LargeMenu'}>{menuOptions}</div>

                <div className={'CollapsedMenu'}>
                    <LazyMenu
                        id={'MobileMenu'}
                        reactLazyFunc={() => import('./DenseAppBar/MobileMenu')}
                        ButtonA={({ onClick }) => (
                            <IconButton
                                className={'mobileMenuButton'}
                                color='inherit'
                                aria-label='Menu'
                                onClick={onClick}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        menuOptions={kbLangKey ? menuOptions : menuOptions.slice(Math.max(menuOptions.length - 3, 1))}
                    />
                </div>
            </div>
        </div>
    )
})

export default DenseAppBar
