import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

const _Link = ({ href, children }) => {
    const router = useRouter()

    const className = `${children.props.className}${router.asPath.split('?')[0] === encodeURI(href) ? ' selected' : ''}`

    return <Link href={href}>{React.cloneElement(children, { className })}</Link>
}

export default _Link
