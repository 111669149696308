import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from 'next/link'
import HomeIcon from '@material-ui/icons/Home'
import { sitePath, keyboardLanguages } from '../../StaticData/SiteData'

const useStyles = makeStyles((theme) => ({
    bread: {
        color: '#bfbfbf',
        fontSize: '15px'
    },
    link: {
        display: 'flex',
        color: '#bfbfbf',
        fontSize: '15px',
        direction: 'ltr',
        textDecoration: 'none'
    },
    hover: {
        '&:hover': {
            color: '#ffffff !important'
        }
    },
    icon: {
        marginLeft: theme.spacing(0.7),
        width: 20,
        height: 20
    }
}))

const section2locize = {
    trans: 'UI.(section)Translation',
    google: 'UI.(button)Google',
    wiki: 'UI.(button)Wikipedia',
    email: 'UI.(section)Send Email'
}

export default function LogoBreadcrumbs({ t, i18n, kbLangKey, sectionKey }) {
    const classes = useStyles()

    return (
        <Breadcrumbs aria-label='breadcrumb' separator='/' className={`${classes.bread} selectable`}>
            <Link
                // className='UpperLeftLogo g2hlogo-up'
                href={sitePath.keysToPath('', '', i18n)}
                aria-label={t('UI.Change keyboard language')}
            >
                {/* <Link color='inherit' href='/' className={classes.link}> */}
                <a className={`${classes.link} ${classes.hover}`}>
                    <HomeIcon className={classes.icon} />
                    <span className='g2hlogo-up'>Gate2Home</span>
                    <style jsx>
                        {`
                            .g2hlogo-up {
                                display: none;
                                padding-left: 5px;
                            }
                            @media (min-width: 720px) {
                                .g2hlogo-up {
                                    display: flex;
                                }
                            }
                        `}
                    </style>
                </a>
            </Link>
            {sectionKey !== 'freetext' ? (
                <Link
                    href={sitePath.keysToPath(kbLangKey, '', i18n)}
                    // style="text-decoration: none;"
                >
                    <a className={`${classes.link} ${classes.hover}`}>
                        {keyboardLanguages.keyToLocaleName(kbLangKey, i18n, false)}
                    </a>
                </Link>
            ) : (
                <span color='textPrimary' className={classes.link}>
                    {keyboardLanguages.keyToLocaleName(kbLangKey, i18n, false)}
                </span>
            )}

            {sectionKey !== 'freetext' && (
                <span className={`${classes.link} ${classes.hover}`}>{t(section2locize[sectionKey])}</span>
            )}
        </Breadcrumbs>
    )
}
