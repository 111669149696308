import { Button } from '@material-ui/core'
import upperFirst from 'lodash.upperfirst'
import React, { useCallback, useEffect, useRef } from 'react'

import AdsenseAd, { AdsenseAdVisible } from '../Components/AdsenseAd'
import Image from 'next/image'

import { keyboardLanguages, locales, sitePath } from '../StaticData/SiteData.js'
import Link from '../../components/Link'
import { useRouter } from 'next/router'
import { useLoadFacebook } from './useFacebook'
import { addthisLoad, AddThisToolbox } from './AddThis'

export const flags_base = '/Images/flags/flags-iso/flat/24/'

export const flags = {
    he: 'IL',
    fr: 'FR',
    en: 'GB',
    es: 'ES',
    de: 'DE',
    ar: 'SA',
    ru: 'RU',
    fa: 'IR',
    ko: 'KR',
    ur: 'PK',
    hi: 'IN'
}

const Footer = React.memo(function Footer({ locale, i18n, kbLangKey, sectionKey, showFooter }) {
    const socialBottomRef = useRef(`socialBottom_`)

    const router = useRouter()
    const { loadFacebook } = useLoadFacebook()

    const changeLanguage = useCallback(
        (lng) => {
            const engPath = sitePath.keysToPath(kbLangKey, sectionKey, i18n, 'en')
            router.push(engPath, engPath, { locale: lng })
        },
        [i18n, kbLangKey, router, sectionKey]
    )

    useEffect(() => {
        if (showFooter) {
            loadFacebook()
            addthisLoad()
        }
    }, [loadFacebook, showFooter])

    // const footer_lang_links = keyboardLanguages.allSortedLocaleNamePlusNative(i18n).map(obj => (
    //     <Link key={obj.key} href={sitePath.keysToPath(obj.key, '', i18n)}>
    //         {obj.value}
    //     </Link>
    // ))
    // const footer_lang_chunks = [] //  lighthouse requirement: 'Have a parent node with more than 60 child nodes.'
    // for (let i = 0; i < footer_lang_links.length; i = i + 59) {
    //     footer_lang_chunks.push(footer_lang_links.slice(i, i + 59))
    // }
    return (
        <div>
            {kbLangKey && (
                <div id={socialBottomRef.current} className='FooterWithSocial'>
                    <style jsx>
                        {`
                            .FooterWithSocial {
                                align-items: center;
                                max-width: 1440px;
                                margin: 0 auto;
                                margin-bottom: 24px;
                            }

                            .socialBottom {
                                clear: both;
                                text-align: center;
                                color: white;
                                margin: 16px 63px;
                                background-color: #d6d6d6;
                                padding: 12px 28px;
                                border-radius: 4px;
                                border: #9db4c9;
                                border-style: solid;
                                height: 30px;
                            }
                            @media print {
                                .socialBottom {
                                    display: none;
                                }
                            }

                            @media (max-width: 720px) {
                                .socialBottom {
                                    height: auto;
                                    text-align: center;
                                    margin-left: auto;
                                    margin-right: auto;
                                    width: 81%;
                                }
                            }
                            .fbLike {
                                float: left;
                            }
                            @media (max-width: 980px) {
                                .fbLike {
                                    display: block;
                                    float: none;
                                    margin: 20px 0 20px 0;
                                    width: 267px;
                                }
                            }
                        `}
                    </style>
                    <div>
                        <div className={'socialBottom'}>
                            <AddThisToolbox url={sitePath.keysToPathWithLocale(kbLangKey, sectionKey, i18n, locale)} />
                            <div
                                className={'fbLike'}
                                data-href='http://www.facebook.com/Gate2Home'
                                data-layout='standard'
                                data-action='recommend'
                                data-width='310'
                                data-size='small'
                                data-show-faces='false'
                                data-share='false'
                            />
                        </div>
                    </div>

                    <div className={'socialBottom'}>
                        {showFooter && (
                            <AdsenseAd
                                adSlot='3974121641'
                                updateKey={'lazy-bottom-unit' + kbLangKey + sectionKey}
                                adFormat='auto'
                            />
                        )}
                    </div>
                </div>
            )}
            <footer className='Footer'>
                <style jsx>{`
                    .Footer {
                        background-color: #2c2b2a;
                        border-top: 1px solid #6ea4cd;
                        padding-top: 41px;
                        padding-bottom: 12px;
                        font-size: 12px;
                        text-align: center;
                        width: 100%;
                        color: #bababa;
                        line-height: 1.35;
                    }
                    .changeLanguage {
                        text-decoration: none;
                        cursor: pointer;
                    }

                    .copyright {
                        margin-top: 6px;
                        direction: ${i18n.dir()};
                    }

                    .copyright a {
                        text-decoration: none;
                        color: #bababa;
                    }

                    .footer-sections {
                        font-size: 14px;
                        color: #bababa;
                        line-height: 3.35;
                    }

                    .footer-sections .label {
                        white-space: nowrap;
                        padding-right: 10px;
                    }

                    .footer-sections a {
                        white-space: nowrap;
                        color: #bababa;
                        margin: 0 5px;
                    }

                    .footer-sections a:hover,
                    .footer-sections a.selected {
                        background-color: #383635cf !important;
                    }
                    .footer-sections a:hover {
                        color: #e9e9e9;
                    }

                    .footer-link-unit-respons {
                        height: 90px;
                        max-width: 728px;
                        text-align: center;
                        margin: 20px auto;
                    }
                    .sitelanguage {
                        display: inline-block;
                    }
                    @media (max-width: 467px) {
                        .footer-link-unit-respons {
                            height: 90px;
                            width: 100%;
                        }
                    }
                    .NavLink {
                        font-size: 14px !important;
                        text-decoration: none !important;
                        text-transform: inherit !important;
                        color: #bfbfbf !important;
                        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
                        padding: 12px 15px 14px !important;
                    }
                    .NavLink:hover {
                        color: #fff !important;
                    }

                    @media (max-width: 960px) {
                        .NavLink {
                            font-size: 16px !important;
                            padding: 7px 30px !important;
                        }
                    }
                `}</style>
                <div className='fullwidth'>
                    {/* sections */}
                    <>
                        {kbLangKey && (
                            <>
                                <div className='selectlang footer-sections'>
                                    <span className='label'>
                                        {kbLangKey &&
                                            upperFirst(
                                                i18n.t('UI.(section){KeyboardName} Sections', {
                                                    KeyboardName: keyboardLanguages.keyToLocaleName(kbLangKey, i18n)
                                                })
                                            ) + ':'}
                                    </span>
                                    <Link key='1' href={sitePath.keysToPath(kbLangKey, 'freetext', i18n, locale)}>
                                        <a className={`NavLink`}>
                                            {/* NavLink Section */}

                                            {i18n.t('UI.(section)Keyboard')}
                                        </a>
                                    </Link>

                                    {/* search */}
                                    <Link href={sitePath.keysToPath(kbLangKey, 'google', i18n)}>
                                        <a className={`NavLink`}>
                                            {i18n.t('UI.(section){Lang} Google', {
                                                Lang: ''
                                            })}
                                        </a>
                                        {/* Todo: 'Arabic Google'. translate pattern '{langname} Google'  */}
                                    </Link>

                                    <Link href={sitePath.keysToPath(kbLangKey, 'wiki', i18n)}>
                                        <a className={`NavLink `}>
                                            {i18n.t('UI.(section){Lang} Wikipedia', {
                                                Lang: ''
                                            })}
                                        </a>
                                    </Link>
                                    {/* end search */}

                                    <Link key='3' href={sitePath.keysToPath(kbLangKey, 'email', i18n, locale)}>
                                        <a className={`NavLink`}>{i18n.t('UI.(section)Send Email')}</a>
                                    </Link>
                                    <Link key='4' href={sitePath.keysToPath(kbLangKey, 'trans', i18n, locale)}>
                                        <a className={`NavLink`}>{i18n.t('UI.(section)Translation')}</a>
                                    </Link>
                                </div>

                                {/* <div className='hr-short' /> */}
                            </>
                        )}
                        {/* end sections */}
                    </>

                    {/* <div className='selectlang footer-links'>
                    {footer_lang_chunks.map(chunk => (
                        <span>{chunk}</span>
                    ))}
                </div> */}
                    {/* <div className='hr-short' /> */}
                    {/* <div id='footer-link-units-div'> */}
                    <div className='footer-link-unit-respons'>
                        {/* <!-- starter-bottom-links --> */}
                        {/* TODO: had className="hidden-phone" */}
                        {/* {!kbLangKey && ( */}
                        <AdsenseAdVisible
                            adSlot='9883681401'
                            updateKey={kbLangKey + 'home-footer-unit'}
                            adFormat='link'
                            isVisible={true}
                        />
                        {/* )} */}
                    </div>

                    {/* <div className='hr-short' /> */}
                    {/* </div> */}
                    <div className='changeLanguage'>
                        {i18n.t('UI.Site language')}: &nbsp;
                        <span className='sitelanguage'>
                            {locales.data.map((locale) => (
                                <Button
                                    key={`flag-span-${locale}`}
                                    id={`flag-span-${locale}`}
                                    onClick={() => changeLanguage(locale)}
                                    title={keyboardLanguages.data[locale].name_native}
                                    style={{ margin: 0 }}
                                >
                                    <Image
                                        alt={keyboardLanguages.data[locale].name_native}
                                        src={'/images/flags/flags-iso/flat/24/' + flags[locale] + '.png'}
                                        width={24}
                                        height={24}
                                    />
                                </Button>
                            ))}
                        </span>
                    </div>

                    <div className='copyright'>
                        <Link href={sitePath.keysToPath('', '', i18n)}>
                            <span>&copy; Gate2Home.com - {i18n.t('BRAND.Type in your language, anywhere')}.</span>
                        </Link>

                        {!kbLangKey && (
                            <a
                                className='debugger_ru'
                                href='http://debugger.ru/projects/virtualkeyboard'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {' '}
                                (&copy; VirtualKeyboard 3.7)
                            </a>
                        )}

                        <a href='/privacy' rel='noopener noreferrer nofollow'>
                            {' '}
                            (Privacy Policy)
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    )
})

export default Footer
