import Head from 'next/head'
import React from 'react'
import Locales from '../StaticData/Locales.js'
import { keyboardLanguages, sitePath } from '../StaticData/SiteData.js'

// function shuffle(sourceArray) {
//     for (var i = 0; i < sourceArray.length - 1; i++) {
//         var j = i + Math.floor(Math.random() * (sourceArray.length - i))

//         var temp = sourceArray[j]
//         sourceArray[j] = sourceArray[i]
//         sourceArray[i] = temp
//     }
//     return sourceArray
// }

// console.log(JSON.stringify(shuffle(Object.keys(keyboardLanguages.data))))

// console.log(res.slice(0, Math.ceil(res.length / 2)))

//58

/*
Tests:
- 1st remark 23/11/2020 compared last week to two weeks ago (15/11-21/11 vs 1/11-7/11)
    https://analytics.google.com/analytics/web/#/report/acquisition-sc-landingpages/a231007w366616p331768/_u.date00=20201116&_u.date01=20201122&_u.date10=20201102&_u.date11=20201108&search_console-table.plotKeys=%5B%5D&search_console-table.rowCount=250&search_console-table.advFilter=%5B%5B0,%22analytics.landingPagePath%22,%22EW%22,%22-Keyboard%22,0%5D%5D/
- 2nd remark 1/12/2020 compared last week to week before (25/11-29/11 vs 18/11-22/11)
        https://analytics.google.com/analytics/web/#/report/acquisition-sc-landingpages/a231007w366616p331768/_u.date00=20201125&_u.date01=20201129&_u.date10=20201118&_u.date11=20201122&search_console-table.advFilter=%5B%5B0,%22analytics.landingPagePath%22,%22EW%22,%22-Keyboard%22,0%5D%5D&search_console-table.plotKeys=%5B%5D&search_console-table.rowStart=0&search_console-table.rowCount=250/
    combined: 15/11-29/11 vs 25/10-8/11
    https://analytics.google.com/analytics/web/#/report/acquisition-sc-landingpages/a231007w366616p331768/_u.date00=20201115&_u.date01=20201129&_u.date10=20201025&_u.date11=20201108&search_console-table.advFilter=%5B%5B0,%22analytics.landingPagePath%22,%22EW%22,%22-Keyboard%22,0%5D%5D&search_console-table.plotKeys=%5B%5D&search_console-table.rowStart=0&search_console-table.rowCount=500/
    13/12/2020....
        combig: 18/11-11/12 vs 14/10-6/11  
        https://analytics.google.com/analytics/web/#/report/acquisition-sc-landingpages/a231007w366616p331768/_u.date00=20201118&_u.date01=20201211&_u.date10=20201014&_u.date11=20201106&search_console-table.advFilter=%5B%5B0,%22analytics.landingPagePath%22,%22EW%22,%22-Keyboard%22,0%5D%5D&search_console-table.plotKeys=%5B%5D&search_console-table.rowCount=500/
        combig_2nd: 25/11-11/12 vs 21/10-6/11
        https://analytics.google.com/analytics/web/#/report/acquisition-sc-landingpages/a231007w366616p331768/_u.date00=20201125&_u.date01=20201211&_u.date10=20201021&_u.date11=20201106&search_console-table.advFilter=%5B%5B0,%22analytics.landingPagePath%22,%22EW%22,%22-Keyboard%22,0%5D%5D&search_console-table.plotKeys=%5B%5D&search_console-table.rowCount=250/
Gerneral remarks:

-  test 2:
    - main / page somehow got bad results (though not changed!). specially on searching 'virtual keyboard'. related to having less 'virtual keyboard' keyword on other pages?
    - on 'short': seems that 'type' is not that popular and ruins the 'X keyboard' pattern which is more popular on Semrush. type should move to description probably.
    - on 'short' I have a feeling that fucked up title fucks clicks, but the better desc raises position/impressions
    - seems that I should go over each page and create titles and descriptions based on the keywords and thats it. 
       my leading keywords and maybe competitors also (SEO research) AND ALSO (AND MAINLY) TEXT IN THE SITE for the specific ones!
*/

/* LOGGGG 3rd

    'kn', //Kannada. cut native (but only 'keyboard' word. 'Type Kannada without Kannada Keyboard! ™ ಕನ್ನಡ ...'). +4.90%.  better pos 11vs12
    //2nd: i+3.4cl-6.8ctr-10pos12vs11. not really better than last week.
    //2nd combined: i+16cl0ctr-13pos11vs13. better pos and impressions, bad ctr.
    // 'kannada keyboard' got better (and in title). should have it in title (like here). so actually good.
    // combig: i+7.6,cl-0.85,ctr-7.8,pos 11vs12. 'kannada keyboard' worse (i-12,cl-16,ctr-1,pos9vs8.8)
    // moved to botique

    second:
    ====  bad 1 bad 2 (first, 'without')====
    'hi', //Hindi cut in native like kannda, no description  ctr -15.43% (try 'short' instead)
    // shows 'Hindi Keyboard (हिंदी कीबोर्ड) - Gate2Home' as title (ignored new title) on 'hindi keyboard'. but shows title on hindi keyboard online and got borderTopLeftRadius
    // ..2nd: -27% on clicks when searching hindi keyboard, +15% on 'hindi keyboard online'. hindi keyboard should be on title!!!!
    // combig: i-7cl-27ctr-21pos9.5vs9.7.
    // combig_2nd: i-7.8cl-26ctr-29pos9.8. still bad.  try botique now!

    ==== good 1 good2 (first, 'without')====
      'ur', //urdu. shows full, ctr +14.84%, but position bad (7.1 vs 6.7) (try more)
    // 2nd: very good! i+5.8cl+12.14ctr+5.96pos6.4vs7.2 !!!!
    // 2nd combined: i+7.5cl+12.6ctr+4.8pos6.7vs6.8. yepp, this one is good!
    // combig: i+0.75cl+5ctr+4.3pos6.6vs6.5(down). good enough!!, botique based on 'without' (to include some more keywords)

    ==== try more 1 (first, 'without') try more 2  (first, 'without')= ====
        'ja', //japanese. -8.12% ctr, but better pos (8.5 vs 8.8)  but shows better than last week
        //2nd: +11ctr. pos8vs8.3 (getting better...) +6.6 on clicks. only bad: -4% impressions.
        //2nd combined: -11Ctr, pos8.2vs8.7, -13 on clicks. -1.7 on impressions. very inconculsive, combined bad, but good on 2nd. try more!
        // combig: i-7.7cl-20ctr-13pos8.3vs8.7 botique please....
        'zh', // Chinese. full.  +10.04%, but not better position and less clicks last week
        //2nd: i-11cl-15ctr-5pos8.9vs9.6. bad
        //2nd combined: i+4.5cl+5.6ctr+1pos9.1vs8.8. so overall up, but 2nd bad. try more?
        //combig: i+5.8cl-2.5ctr-7.89pos6.2vs5.9. bad. botique please...

    ====  try more 1 bad 2 (first, 'without')====
    'he', //1st: full, ctr -4.51%, but better position: 3.7 vs 4.3 (so try more 'without')
    // 2nd: ctr -3%, postion 7 vs 3.9!!!!! (really bad!!) clicks -9.3%!!!  ctr -3%, impressions -6.45%!!!
    // 2nd combined: i-0.5cl-7.6ctr-7.2pos4.9vs5.4... less bad, but 2nd regular really bad so bad change in last week mainly!!!
    // combig: i-3.5cl-4CTR-0.5pos4.8vs6.2(better pos...)
    // keywords: 'hebrew keyboard' better, virtual keyboard big drop (31 clicks to 3, pos), mikledt more, 'hebrew keyboard online' less,
    // virtual hebrew keyboard less, hebrew virtual keyboard less,  type in hebrew more, מקלדת עברית more (but worse pos with more imp?!) ...
    // try botique combination of last and 'without'
    // 22/12/2020 !!!!!! really bad!!!!
   'mr', //Marathi, -34.07%, trunced in native, no description. but better than last week in search term.
   //2nd i-10.5cl-29ctr-20pos9.9vs9.3. all bad.
   //2nd combined: i+19cl-26ctr-37pos9.6vs12. more impressions, better pos. but 2nd shows last week was bad. impressions +19% is 6K people so
   //...2nd combined: so maybe desc pushed it up but title got less clicks
   // combig: i+13.2cl-33ctr-41pos9.8vs11. try botique.
   'th', //Thai. full.  -0.04%	, better postion (4.3 vs 4.7). but less clicks and impressions overall (also compared to last week)
   //2nd: i-1.3cl-8ctr-6pos4.8vs4.5. quite bad.
   //2nd combined: all bad, pos4.5vs4.6. bad.
   //combig: i-5.8cl-17ctr-12pos4.5vs4.6. bad, go botique.
    'ml', // Malayalam. no native. -23.39%	but better pos 12 vs 13.
    //2nd: i-7cl-26ctr-20pos12same. bad on clicks.
    //2nd combined: i+7cl-24ctr-29pos12same. bad bad bad
    //combig: i-5cl-31ctr-27pos13 bad bad bad do botique!!
    ==== none 1 try more 2 (second, short) ====
     'fa', //Persian Farsi 
        //2nd. (tried short). ctr-1,click+2.25,pos5.8vs5.5. inconclusive, but title not taken! 'Farsi-Persian Keyboard (صفحه کلید فارسی) - Gate2Home'. but confusing results
        //...2nd maybe should be bad and include "farsi/persian keyboard" on title? decide
        // combig_2nd: i+10.5cl+17.3ctr+6pos5.9vs5.8(down abit). but short looks working.  but title still wrong (above).  title copeied is the one above 
        // but actually shown like this: 'Gate2Home - (صفحه کلید فارسی) Farsi-Persian Keyboard' (reveresed).
        // maybe should be new title + existing 'short' desc. moved to botique like that 
    'de', //2nd. german. i+12c-3ctr-13pos8.3vs9.1. inconclusive. title not right 'German Keyboard (Deutsche Tastatur) - Gate2Home'. should have keyboard in title?
    // combig_2nd: bad, try botique

    ==== none 1 bad 2 (second, short)====
    'en', //2nd.(short). title 'English Keyboard - Gate2Home' !!!  i-1.2cl-1.7ctr-0.5pos4vs3.1. leaning bad,  still inconclusive, but test not valid no title!!!
    //2nd combined: though no change before, got a drop of clicks -7.6 and ctr-10 for no apperant reason.
    // combig_2nd: i+1.6cl+1.3ctr-0.3pos3.8vs3.6.  I say rebuild a botique one (english keyboard in gerneral is a dieffernt page)    
    // trying a title of 'without' for first time... (seems suitable to english keyboard)

    'ru', // 2nd (short). title like hindi 'Russian Keyboard (Русский Клавиатура) - Gate2Home' i+44!!!c-40!!ctr-57!!pos8.6vs7.1 very extreme! 
    // 'russian keyboard' got 193% more impressions (6.8K vs 2.3K !!! can't ignore!)
    //...2nd but clicks18vs10 (nothing really) cause pos 9.9vs10 really low. but impressions high!. so something worked here a little.
    // ...2nd 'russian keyboard' on SEMrush 140K 'type russian' 10K. so maybe should not have type in title
    // combig_2nd: i-28cl-37ctr-13pos8.1vs6.5.  botique now!

    ==== bad 1 (without) bad 2 (short)====
      'ta', //tamil. not full, no description - ctr -21.92%
      // 2nd: also title issue. i-12cl-12ctr0pos9.6vs10.  less clicks.
      // 2nd combined even worse without reason (was 'none' before) i+4.6cl-24ctr-6.7pos7.7vs6.4.  so only thing better in 'short' is slightly better pos
      // combig_2nd: i-12.69,cl-24.6ctr-13.6pos10(same). just bad both. botique!!
============= abover pushed on 13/12/2020 SEO 3rd first batch========



=== 18/12/2020: decision: looks like 'second' using 'short' was porblematic from the start (on 23/11/2020). Reverting left 'second' array to previous (locale seo) 
            also, looking at 'second' data from previous research - all seem bad!!! doesnt really work!!!
            Alright switched 'short' to a new one calld 'smarter' (on 'second' array), 
            made some small changes to 'first' titles (documented below near the chanage)
            this batch is called SEO 3rd.5 batch!
*/

const first = [
    /*==== good 1 good2====*/
    'ar', //shows full ctr +9.78%, pos -2.96%
    // 2nd: really good! i+2.3%, cl+13.19%, CTR +10.65%, pos6.4vs7!!!!!
    // 2nd combained: i+7,cl+27,ctr+18,pos6.7vs7.3  extremely good!
    // combig: i+7.3,ck+30,ctr+21.83,pos6.5vs7.2 yepppp very good. keep 'wihtout'!
    'ko', //Korean
    //shows full ctr +6.66%, pos -2.35% (good! keep without)
    //2nd: mmm... ctr+5.56%, pos6.7vs6.9, but clicks -6.52%, impressions -11.44%.  keep it in good for now, but to watch out!
    //2nd combined: ctr+14.6 pos6.7vs7.1 clicks +17.8, impressions -2.7. looks way better only imressions down!
    // combig: i+3.9,cl+20,ctr+15.5,pos6.8vs6.9 great, keep 'without'! added 'Hangul' keyword to desc.
    // =====================

    'mn', // mongolian. cut native but like Kannada.()...golian Keyboard! ™ Монгол ...), no desk. +42.52%(!!) g6.6vs7.4
    //2nd. i+3.5,cl+6.6ctr+3pos6.8vs6.5
    //2nd combined: i+2.2cl+22ctr+19.6pos6.7vs7.4 extremely good!
    // 'Kannada' pattern works here also in title. but probably must have 'X keyboard' in description as well.
    /*====  try more 1 good 2 ====*/
    'syr', //Syriac. no native. +89% but very small
    //2nd combined: i+75cl+100ctr+14pos8.5same. more ctr more impr. (still very small imp467vs324)
    /*====  try more 1 bad 2 ====*/
    'bg', //Bulgarian. full, and cut like Kannada. -24.47% but g6vs7.3.
    // 2nd combined: i+1.55cl-16ctr-17pos5.9vs6.6. bad, but better pos again. but still ctr down
    /*==== good 1 ====*/
    /*==== try more 1 try more 2====*/
    'hu', //Hungarian. like kannada.  -4.38%, but more impressions and clicks g5.4vs5.5
    //2nd combined: i+26cl+26ctr-0.2pos5.6vs5.8 more clicks and impr.    .
    'vi', //Vietnamese. cut native, but keyboard before name (not like Kannada). -43.57 but g11vs12. and x2 impressions
    //2nd combined: i+33,cl-6ctr-29pos11same. more impressions, but not more clicks.
    'it', //Italian. full. too small.
    //2nd combined: i+46cl+15(15clicks...)ctr-21pos10vs9.9. can't really know. more imp. too small still?
    'cs', //Czech. full, but no desc. -26.35% but 11vs13. too small
    //2nd combined: seems same like italian.
    'mt', //Maltese. full. -11.79%. worse pos. small numbers.
    //2nd combined: i+3.8cl+34ctr+29pos5.1vs4.8 all better but impr and pos
    'lo', //Lao. full. -15.84%. pos nochange. small numbers,
    //2nd combined: i+24cl+5ctr-15pos6.4vs6.9. all up but ctr.
    // though full, with 'lao keyboard' in title and desc, 'lao keyboard' data is down (but pos better)
    'sq', //Albanian. full -36.34%	but 5.8vs7.1
    // 2nd combined: same down but better pos
    /*==== try more 1 ====*/
    /*==== the rest ====*/
    'ig',
    'om',
    'lv',
    'st',
    'ki',
    'ak',
    'ug',
    'kro',
    'fae',
    'bla',
    'tk',
    'lb',
    'lg',
    'is',
    'bm',
    'so',
    'ga',
    'ff',
    'tl',
    'kri',
    'kk',
    'nl',
    'be',
    'gbe',
    'ne',
    'sk',
    'tg',
    'gaa',
    'bem',
    'ba',
    'luo'
]

const second = [
    /*==== none 1 try more 2 (second, short) ====*/
    'bn', //Bengali -28.98%	without any change
    //2nd: ctr +20%. clicks +181% (but small number 31vs11). impressions 134% (2.9Kvs1.2K) pos12vs15.
    //2nd title bad: 'Bengali Keyboard (বাংলা কীবোর্ড) - Gate2Home'. seems that having 'bengali keybord' in title is good.
    //2nd: not really 'try more' nor 'bad' but 'try with keyboard'
    // actually 2nd was really good because of 'bangla keyboard online' search raising imp 597vs179 while 'bengali' keyboard going down (483vs206).
    // this search showed the right title (but not desc, it tries to take it from the text in the site should have supporting text in site! now!)
    // so should be in this case both 'X keyboard' and 'online'. and the word 'bangla' which appears a lot on searches.
    'dev', //Devanagari, +21.26%	without any change, also pos 6.8vs8.2
    //2nd i-1.5cl+38ctr+40pos7vs6.9 page impressions low in general 1805vs1834. title 'Devanagari Keyboard - Gate2Home' + desc.
    // numbers too low to conculde anything.
    // 2nd try more?
    'uk', //Ukrainian +39.36% without any change
    //2nd: i-2cl-12ctr-10pos9.2vs8.2 all bad. small numbers (~1600im). maybe 'fix' for the +39% on 'none'?
    // ..2nd: title bad 'Ukrainian Keyboard (Українська Клавіатура) - Gate2Home' + desc.
    // cant really decide, seems random
    'es', //Spanish
    //2nd combined: all down. but small numbers. pos on 'spanish keyboard' way better 13vs23 (but 1 click vs 0 :)
    /*==== none 1 bad 2 ====*/
    'tr', //Turkish. 2nd: i+6cl+3.5ctr-2pos7.1vs6.9 title bad, no desc. "turkish keyboard" was actually way better. 'Turkish Keyboard (Türkçe Klavye) - Gate2Home'
    //2nd: 'none' version had title "Virtual keyboard in {Lang} ™{extra}". so seemes that having 'turkish keyboard' in title works better.
    //2nd: should have turkish keyboard in title and desc.
    'fr', // French. 2nd: i-6.5cl-37ctr-33pos9.5vs8.7.  looks bad. searching 'french keyboard' says last 7 days impressions +20%. 3 clicks? oy very long tail.
    //..2nd shows 'French Keyboard (Clavier Français) - Gate2Home' title but shows desc. ALL LONG TAIL!
    /*==== bad 1 (without) bad 2 (short)====*/
    'eg', //Pashto. no native. -18.35%
    //2nd = i-4.6cl-38%ctr-35pos6.4vs6.8.... 'Pashto Keyboard - Gate2Home' (has no native on site). no desc.
    // bad. (no title, no desc,bad results).
    /*==== bad 1 (without) try more(short)====*/
    'te', //Telugu. no native. no desc.  -53.08%, but better pos abit (10 vs 11)
    //2nd: i+10.7cl+56ctr+41pos12vs11. all good but pos. I say try more 'short'. oh... actually didn't catch title nor desc! 'Telugu Keyboard (తెలుగు ... - Gate2Home'
    //...2nd: so its actually 2nd good only compared to 'without', and without right title and desc. which also had no desc and bad title...
    //...2nd: so probably 2nd better by default than the really bad 'without'. "Telugu keyboard" should be on title. and desc probably. but somehow 2nd was better.
    //..2nd: white noise? should go to none?
    'gu', //Gujarati. -24.76%, but better pos abit (10 vs 11)
    //2nd (short): i+22cl+47ctr+20pos:same. seems better than 'without'. try more to verify?
    'el', //Greek full. -15.49%, worse pos 8.7 vs 8.5
    //2nd pos8vs8.8 i-9cl+43(!!)ctr+58(!!). bad title. good only because of 'x keyboard' in search?
    // try more but with 'X keyboard' version...
    /*==== bad 1 (without) good2 (short)====*/
    'hy', //armenian, cut before native! -29.66%	 b4.5vs4.2
    // 2nd. 'Armenian Keyboard (Հայերեն ... - Gate2Home' almost like 'wihtout' test. desc appears. i-4.5 ctr+6.4 pos 4.2vs4.9
    // ...2nd so better pos, and ctr. seems better. small numbers (~1800imp). 'aremenian keyboard' better.
    /*==== bad 1 (without) ====*/
    /*==== the rest ====*/
    'da',
    'ro', //Romanian. full.
    'tn',
    'ce',
    'ky',
    'dmn',
    'tt',
    'sl', //Slovenian ++

    'az',

    'ewo',
    'la',
    'pl', //Polish --
    'rn',
    'eth',
    'et',
    'sv', //Swedish -0
    'dv',
    'ku', //Kurdish 00
    'yo',

    'sa', //Sanskrit
    'mul',
    'ln',
    'mw',
    'sw', //Swahili
    'lt', //Lithuanian --
    'mi',
    'wo',
    'chr',
    'sn',
    'xh',

    'din',
    'ka', //Georgian ++
    'pt', //Portuguese ++
    'za',
    'bt',
    'nn', //Norwegian -+
    'km',
    'gd',

    'mk', //Macedonian +0
    'ha',
    'hr', //Croatian 0+
    'pa',
    'sr', //Serbian ++
    'uz',
    'fi',
    'dua',
    'prs'
]

// console.log(first.map((lang) => '/' + keyboardLanguages.getEnglishSlug(lang)))

const override_seo = (locale, kbLangKey, sectionKey, which, { native_title, Lang, LangFull, Native }) => {
    const desc_without_first_lang_override = {
        ko: `Hangul` //korean. Hangul is the korean alphabet
    }

    const seo_templates = {
        //  none: {
        //     title: "Virtual keyboard in {Lang} ™{extra}",
        //     desc: "The Best {LangFull} on the Internet! Type, Translate, Search, Send emails, tweet, and share with your friends in facebook with this online onscreen virtual keyboard emulator, in all languages",
        // },
        without: {
            // first test was this on half (array first), and none to others
            title: `Type ${Lang} without ${Lang} Keyboard! ™ ${native_title}`,
            desc: `Write in ${
                desc_without_first_lang_override[kbLangKey] || Lang
            } letters using an Online Virtual ${LangFull} with a layout of ${Lang} alphabet characters shown on-screen.`
        },
        // short: {
        //     //23/11/2020 second test was this on the other half, plus a few "bad" failed ones from first test
        //     title: `Type ${Lang} Online ™ ${native_title}`,
        //     desc: `Virtual ${LangFull} for writing text with ${Lang.split('-')[0]} letters on screen. Write in ${
        //         Lang.split('-')[1] ? Lang.split('-')[1] : Lang.split('-')[0]
        //     } WITHOUT ${Lang.split('-')[1] ? Lang.split('-')[1] : Lang.split('-')[0]} Keyboard!`
        //     // 18/12/2020 - I think this one totally failed!!!!
        // },
        smarter: {
            //18/12/2020 using smarter instead of 'short' for 'second'
            title: `${Lang} Keyboard Online | ${native_title} ™`,
            // desc: `Type ${Lang} Keyboard using a ${Lang} Virtual Keyboard (${Native}). Online Typing with ${Lang} layout shown on-screen`
            desc: `Type ${Lang} (${Native}) using a Virtual ${Lang} Keyboard with on-screen ${Lang} layout | Online Typing in ${Lang} WITHOUT ${Lang} Keyboard!`
            // Why?
            // - Starting with 'X keyboard' seems important
            // - 'Online' seems to be joint to most languages. though some language should have 'Typing' instead
            // - '|' seems to work in persian and japanses but needs futher checking. hyposis.
            // - native title with ™ might catch the eye.
            // - thought also of minimalistic version without 'online' :  `${Lang} Keyboard | ${native_title} ™` - might catch the eye even more
        },
        short_keyboard: {
            title: `Type ${Lang} Online ™ ${native_title}`
        },
        ///////botique:
        kn: {
            // Kannada
            title: `English to Kannada Typing Online ™ ${native_title}`,
            desc: `Kannada Keyboard (ಕನ್ನಡ) for Online Kannada Typing. Type in Kannada layout using an Online Virtual Kannada Keypad , Nudi Keyboard, Ottakshara Typing.`
        },
        // hi: {
        //     title: `Hindi Keyboard Typing Online ™ ${native_title}`, // changed from `Hindi Typing Online Keyboard ™ ${native_title}` on 18/12/2020, 'hindi keyboard' should be in title
        //     desc: `Hindi Keyboard (हिन्दी) to Type Online using Virtual Hindi Keypad. Type in Hindi letters with a layout of Hindi alphabet shown on PC/Laptop/Mobile screen.`
        // }, REALLY BAD 22/12/2020 changing to the new title but with the without description:
        hi: {
            title: `Hindi Keyboard Typing Online ™ ${native_title}`, // changed from `Hindi Typing Online Keyboard ™ ${native_title}` on 18/12/2020, 'hindi keyboard' should be in title
            desc: `Write in ${
                desc_without_first_lang_override[kbLangKey] || Lang
            } letters using an Online Virtual ${LangFull} with a layout of ${Lang} alphabet characters shown on-screen.`
        },
        ur: {
            title: `Type Urdu Keyboard ™ ${native_title}`, // changed from `Type ${Lang} without ${Lang} Keyboard! ™ ${native_title}` on 18/12/2020
            desc: `Online Urdu Typing using an Online Virtual Urdu Keyboard (اردو). Urdu writing with a phonetic keyboard using a layout of Urdu alphabet shown on screen.`
        },
        fa: {
            title: `Type Farsi Keyboard | Persian Keyboard ™ ${native_title}`,
            //Virtual Farsi-Persian Keyboard (فارسی) for writing text with Farsi letters on screen. Write in Persian WITHOUT Persian Keyboard!
            desc: `Typing in Persian WITHOUT Persian Keyboard! Farsi Virtual Keyboard / Kibord Farsi (فارسی) for writing text with Persian letters on screen. `
        },
        //Virtual keyboard in Hebrew ™ (מקלדת עברית)
        //The Best Hebrew Keyboard (עברית) on the Internet! Type, Translate, Search, Send emails, tweet, and share with your friends in facebook with this online onscreen virtual keyboard emulator, in all languages
        // he: {
        //     title: `Virtual Hebrew Keyboard Online ™  (מקלדת עברית)`, //`Type Hebrew without Hebrew Keyboard! ™ (מקלדת עברית)`,
        //     desc: `Type in Hebrew (Ivrit) using an Online Hebrew Virtual Keyboard with Hebrew Layout alphabet characters.`
        //     //`Type in Hebrew letters using an Online Virtual Hebrew Keyboard (עברית) with a layout of Hebrew alphabet characters shown on screen.`
        // }, REALLY BAD 22/12/2020 changing to ORIGINAL:
        he: {
            title: `Virtual keyboard in Hebrew ™ (מקלדת עברית)`,
            desc: `The Best Hebrew Keyboard (עברית) on the Internet! Type, Translate, Search, Send emails, tweet, and share with your friends in facebook with this online onscreen virtual keyboard emulator, in all languages`
        },
        en: {
            title: `Type English Online WITHOUT an English Keyboard! ™`, //Type English Online ™
            desc: `Use our Virtual Keyboard in English to type text with English characters on-screen. Try different layouts: QWERTY, US Dvorak, British, Phonetic and more.`
            //Virtual English Keyboard for writing text with English letters on screen. Write in English WITHOUT English Keyboard!
        },
        ru: {
            title: `Virtual Russian Keyboard Online ™ Русский Клавиатура`, //`Type ${Lang} Online ™ ${native_title}`, //Type Russian Online ™ Русский Клавиатура
            desc: `Russian Virtual Keyboard (Russkaya Klaviatura) for writing text with Russian (Русский) letters on-screen. Write in Russian WITHOUT RU Keyboard!`
        },
        ta: {
            title: ` Tamil Keyboard Typing Online ™ ${native_title}`, // changed from `English to Tamil Typing Online ™ ${native_title}` on 18/12/2020
            desc: `${LangFull} for Online Tamil Typing. Type in Tamil using a Tamil Keypad with a Bamini Keyboard Layout. Tamil Keyboard WITHOUT a keyboard!`
        },
        ja: {
            title: `Japanese Keyboard | Hiragana Keyboard ™ ${native_title}`, //`Type ${Lang} without ${Lang} Keyboard! ™ ${native_title}`,
            desc: `How to Type in Japanese? Use our Japanese Keyboard Online WITHOUT actual Keyboard Jepang! Typing Romaji characters on-screen`
            //     `Write in ${
            //         desc_without_first_lang_override[kbLangKey] || Lang
            //     } letters using an Online Virtual ${LangFull} with a layout of ${Lang} alphabet characters shown on-screen.`
        },
        mr: {
            title: `English to Marathi Typing Keyboard Online ™ ${native_title}`, //`Type ${Lang} without ${Lang} Keyboard! ™ ${native_title}`,
            desc: `${LangFull} for Marathi Typing Online. Type in Marathi using a Marathi input. Online Marathi Keyboards WITHOUT a keyboard!`
        },
        // 'zh', // Chinese. full.  +10.04%, but not better position and less clicks last week
        // //2nd: i-11cl-15ctr-5pos8.9vs9.6. bad
        // //2nd combined: i+4.5cl+5.6ctr+1pos9.1vs8.8. so overall up, but 2nd bad. try more?
        // //combig: i+5.8cl-2.5ctr-7.89pos6.2vs5.9. bad. botique please...
        zh: {
            title: `Chinese Keyboard Typing Online ™ ${native_title}`, // changed from `Type Chinese Keyboard Online ™ ${native_title}` on 18/12/2020 (want to start with 'X Keyboard')
            desc: `Type in Chinese online using a Chinese Input (中文) with a layout of Cangjie / Traditional Pinyin alphabet characters shown on-screen.`
            //Write in Chinese letters using an Online Virtual Chinese Keyboard (中文) with a layout of Chinese alphabet characters shown on screen.
        },
        th: {
            title: `Thai Keyboard Typing Online ™ ${native_title}`, // changed from `Type Thai Keyboard Online ™ ${native_title}` on 18/12/2020 (want to start with 'X Keyboard')
            desc: `Type in Thai using an Online Virtual ${LangFull} with a Thiland Keyboard layout alphabet characters shown on-screen.`
        },
        // 'ml', // Malayalam. no native. -23.39%	but better pos 12 vs 13.
        //2nd: i-7cl-26ctr-20pos12same. bad on clicks.
        //2nd combined: i+7cl-24ctr-29pos12same. bad bad bad
        //combig: i-5cl-31ctr-27pos13 bad bad bad do botique!!
        ml: {
            title: `English to Malayalam Typing Online ™ ${native_title}`, //`Type ${Lang} without ${Lang} Keyboard! ™ ${native_title}`,
            desc: `Type in Malayalam Keyboard Online using a Virtual ${LangFull} with a Malayalam keypad layout alphabet. Malayalam writing on-screen.`
        },
        // 'de', //2nd. german. i+12c-3ctr-13pos8.3vs9.1. inconclusive. title not right 'German Keyboard (Deutsche Tastatur) - Gate2Home'. should have keyboard in title?
        // // combig_2nd: bad, try botique
        de: {
            title: `Type German Keyboard Online ™ ${native_title}`,
            //Virtual Farsi-Persian Keyboard (فارسی) for writing text with Farsi letters on screen. Write in Persian WITHOUT Persian Keyboard!
            desc: `Typing in German WITHOUT German Keyboard! Virtual German Keyboard (Deutsch) for writing text with German characters on screen.  `
        }
    }

    // lang_section = {}
    // first.forEach((lang) => (lang_section[lang] = { freetext: seo_templates['without'] }))

    const seo_override = {
        en: {
            [kbLangKey]: {
                freetext: null
            }
        }
    }
    seo_override.en[kbLangKey].freetext = seo_templates[kbLangKey]
        ? seo_templates[kbLangKey]
        : first.includes(kbLangKey)
        ? seo_templates['without']
        : second.includes(kbLangKey) // 18/12/2020: decision to remove second/short, and replaced with 'smarter'
        ? seo_templates['smarter']
        : null

    return (
        seo_override[locale] &&
        seo_override[locale][kbLangKey] &&
        seo_override[locale][kbLangKey][sectionKey] &&
        seo_override[locale][kbLangKey][sectionKey][which]
    )
}
function HeadMeta({ i18n, kbLangKey, sectionKey, alternates }) {
    // function HeadMeta( { kbLangKey,sectionKey, i18n}) {

    const native_title = kbLangKey && keyboardLanguages.data[kbLangKey].title_native

    const seoMeta = {
        title: kbLangKey
            ? override_seo(i18n.language, kbLangKey, sectionKey, 'title', {
                  native_title,
                  Lang: keyboardLanguages.keyToLocaleLanguageName(kbLangKey, i18n)
              }) ||
              i18n.t(`SEO.(title)(keyboard)`, {
                  // Virtual keyboard in {{Lang}} &trade; {{extra}}
                  Lang: keyboardLanguages.keyToLocaleLanguageName(kbLangKey, i18n),
                  extra:
                      (native_title && kbLangKey !== i18n.language ? ' (' + native_title + ')' : '') +
                      (sectionKey !== 'freetext' ? ' - ' + i18n.t(`SEO.(title)(section)${sectionKey}`) : '')
              })
            : i18n.t('SEO.(title)(home)'),
        description:
            (kbLangKey &&
                override_seo(i18n.language, kbLangKey, sectionKey, 'desc', {
                    Lang: keyboardLanguages.keyToLocaleLanguageName(kbLangKey, i18n),
                    LangFull: kbLangKey && keyboardLanguages.keyToLocaleNamePlusNative(kbLangKey, i18n, false),
                    Native: keyboardLanguages.data[kbLangKey].name_native
                })) ||
            i18n.t(`SEO.(description)(${sectionKey})`, {
                LangFull: kbLangKey && keyboardLanguages.keyToLocaleNamePlusNative(kbLangKey, i18n, false)
            }),
        image:
            '//us-central1-g2h-image-maker.cloudfunctions.net/ogimage/api/ogimage?text=' +
            (
                native_title ||
                (kbLangKey && keyboardLanguages.keyToLocaleName(kbLangKey, i18n, false)) ||
                'Online Keyboard'
            )
                .replace(' ', '_')
                .replace('%20', '_') // fb fails to retreive when there are spaces
    }

    return (
        // <HelmetProvider>

        <Head>
            <title>{seoMeta.title}</title>
            <meta name='description' content={seoMeta.description} />
            <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />

            <link
                rel='canonical'
                href={`https://gate2home.com${sitePath.keysToPathWithLocale(kbLangKey, sectionKey, i18n)}`}
            />

            <link
                rel='alternate'
                href={`https://gate2home.com${sitePath.keysToPath(kbLangKey, sectionKey, i18n, 'en')}`}
                hrefLang='x-default'
            />

            {kbLangKey && alternates && sectionKey && alternates[sectionKey]
                ? alternates[sectionKey].links.map((link) => (
                      <link
                          rel='alternate'
                          key={`hrefLang-${link.lang}-${kbLangKey}-${sectionKey}`}
                          href={link.url}
                          hrefLang={link.lang}
                      />
                  ))
                : Locales.data.map((locale) => (
                      <link
                          rel='alternate'
                          key={`hrefLang-${locale}-${kbLangKey}-${sectionKey}`}
                          href={`https://gate2home.com${sitePath.keysToPath(kbLangKey, sectionKey, i18n, locale)}`}
                          hrefLang={locale}
                      />
                  ))}
            {/* no kbLangKey - home, can generate it without alternates  */}

            <meta property='fb:app_id' content='135016923220292' />
            <meta property='og:title' content={seoMeta.title} />
            <meta property='og:description' content={seoMeta.description} />
            <meta property='og:site_name' content='Gate2Home.com - Online Virtual Text Keyboard' />
            <meta
                property='og:url'
                content={`https://gate2home.com${sitePath.keysToPathWithLocale(kbLangKey, sectionKey, i18n)}`}
            />
            <meta property='og:type' content='website' />
            <meta property='og:image' content={'https:' + seoMeta.image} />
            <meta property='og:image:width' content='1024' />
            <meta property='og:image:height' content='1024' />
            <meta property='og:image:type' content='image/png' />
            {/* <meta property='og:image:secure_url' content={'https:' + seoMeta.image} /> */}
            <meta name='twitter:title' content={seoMeta.title} />
            <meta name='twitter:description' content={seoMeta.description} />
            <meta name='twitter:image' content={'https:' + seoMeta.image} />
            <meta name='twitter:card' content='summary' />
            <meta name='twitter:site' content='@Gate2HomeCom' />
        </Head>
    )
}

export default HeadMeta
