import { useEffect, useState } from 'react'

const queryString = require('query-string')

export default function useSearch(flag) {
    const [query, setQuery] = useState(
        typeof window !== 'undefined' && flag ? queryString.parse(window.location.hash) : {}
    )

    useEffect(() => {
        if (flag) {
            const hashchange = (e) => {
                setQuery(queryString.parse(window.location.hash))
                if (e) window.ga('send', 'pageview')
            }

            if (window.location.hash) hashchange()
            window.addEventListener('hashchange', hashchange, false)
            return () => {
                window.removeEventListener('hashchange', hashchange, false)
            }
        }
    }, [flag])

    return { ...query }
}
