import { useCallback } from 'react'
import { extractContent } from '../aux2.js'
import { useGate2HomeContext } from '../Context/Gate2HomeContext.js'
import { useTextboxContext } from '../Context/TextboxContext.js'

const facebookSdkUrl = 'https://connect.facebook.net/en_US/sdk.js'

const load = require('load-script')

export const useLoadFacebook = () => {
    const [Gate2homeState, Gate2homeDispatch] = useGate2HomeContext()

    const loadFacebook = useCallback(
        (callback = false) => {
            console.log('aaaaaa')
            if (!Gate2homeState.facebookLoaded) {
                Gate2homeDispatch({ type: 'disable-facebook', payload: true })

                window.fbAsyncInit = function () {
                    window.FB.init({
                        appId: '135016923220292',
                        autoLogAppEvents: true,
                        xfbml: true,
                        version: 'v3.2'
                    })
                }

                load(facebookSdkUrl, () => {
                    if (!Gate2homeState.facebookLoaded) {
                        Gate2homeDispatch({ type: 'facebook-loaded', payload: true })
                        Gate2homeDispatch({ type: 'disable-facebook', payload: false })
                        if (callback) callback()
                    }
                })
            }
        },
        [Gate2homeDispatch, Gate2homeState.facebookLoaded]
    )

    return {
        facebookLoaded: Gate2homeState.facebookLoaded,
        loadFacebook
    }
}

export const useFacebook = () => {
    const [TextboxState] = useTextboxContext()

    const { loadFacebook, facebookLoaded } = useLoadFacebook()

    const publishToFacebook = () => {
        // ga('send', 'event', 'Facebook', '3. Calling UI Share', kb_slug, 1, {'nonInteraction': 1});

        window.FB.ui(
            {
                method: 'share',
                display: 'popup',
                href: window.location.href,
                quote: extractContent(TextboxState.textbox1)
            },
            function (response) {
                if (!response || response.error) {
                    // ga('send', 'event', 'Facebook', '3B. Post wasn\'t Shared', kb_slug, 1, {'nonInteraction': 1});
                    var fb_err_title = 'Opps...'
                    // ((typeof response.error.error_user_title)  !== "undefined" ? response.error.error_user_title + ": " : "") +
                    var fb_err_msg =
                        response && typeof response.error.error_user_msg !== 'undefined'
                            ? response.error.error_user_msg
                            : 'Error occurred, Post was not published to Facebook!'
                    // showDialog(fb_err_title,fb_err_msg ,false);
                    console.log(fb_err_title + ' ' + fb_err_msg)
                } else {
                    // ga('send', 'event', 'Facebook', '3A. Post Shared', kb_slug, 1, {'nonInteraction': 1});
                    // showDialog("Great Success!","Status update was published to your facebook timeline",false);
                    console.log(response)
                }
            }
        )
    }

    const handleFacebook = () => {
        if (!facebookLoaded) loadFacebook(publishToFacebook)
        else publishToFacebook()
    }

    return { handleFacebook }
}
