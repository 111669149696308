import { cloneElement } from 'react'
import DenseAppBar from '../src/Components/DenseAppBar'
import FooterWithSocial from '../src/Components/FooterWithSocial'
import HeadMeta from '../src/Components/HeadMeta'
// import useTranslation from 'next-translate/useTranslation'

import Error from 'next/error'
import useGate2HomeRouter from '../src/Hooks/useGate2HomeRouter'

import dynamic from 'next/dynamic'
import useSearch from '../src/Hooks/useSearch'
import { useRouter } from 'next/router'

const FixLanguageURLtoLocale = dynamic(() => import('./FixLanguageURLtoLocale'))

const MyAppInternal = ({ notfound, locale, kbLangSlug, kbLangKey, sectionKey = 'freetext', children, alternates }) => {
    const router = useRouter()
    if (router.isFallback) {
        return <div>Loading...</div>
    }

    const { i18n, t } = useGate2HomeRouter()
    const { q, lang, t: tt } = useSearch(['google', 'wiki', 'trans'].includes(sectionKey))

    return (
        <>
            {locale != 'en' && kbLangSlug && kbLangSlug.indexOf('Keyboard') > 0 && (
                <FixLanguageURLtoLocale {...{ i18n, kbLangKey, sectionKey }} />
            )}

            {sectionKey !== 'privacy' && <HeadMeta {...{ i18n, kbLangKey, sectionKey, alternates }} />}
            <div className={`App dir-${i18n.dir()}`}>
                <style jsx>{`
                    .App {
                        text-align: center;
                        overflow: hidden; /* from gate2home.less */
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                    }

                    :global(.selectable) {
                        -webkit-user-select: text;
                        -moz-user-select: text;
                        -ms-user-select: text;
                        user-select: text;
                    }
                `}</style>
                <DenseAppBar {...{ locale, t, i18n, kbLangKey, sectionKey }} />
                {notfound || !sectionKey ? (
                    <Error statusCode={404} />
                ) : (
                    cloneElement(children, { kbLangKey, sectionKey, t, i18n, q, lang, tt })
                )}
                <FooterWithSocial {...{ i18n, kbLangKey, sectionKey, locale }} />{' '}
            </div>
        </>
    )
}

export default MyAppInternal
