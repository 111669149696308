import memoize from 'fast-memoize'

import keyboardLanguagesJson from './keyboardLanguages.json'
import Locales, { titleAdjustements, slugAdjustements, urlAdjustements } from './Locales'

import { reverseObject } from '../aux2.js'

// const queryString = require('query-string'); // If you want support for older browsers, or use version 5: npm install query-string@5.

//differences between i18next locale format and  ICU format
const seper = { true: ':', false: '.' }
const opener = { true: '{{', false: '{' }
const closer = { true: '}}', false: '}' }

export const keyboardLanguages = {
    data: keyboardLanguagesJson,

    // he => hebrew-keyboard.  i18n.language for cache key.
    getEnglishSlug: (lng) => memoize((lng) => keyboardLanguages.data[lng].name + '-Keyboard')(lng),
    // he => hebrew-keyboard | clavier-angles
    getSlug: (lng, i18n, locale, i18next = false) => {
        locale = locale || i18n.language
        if (locale === 'en') return keyboardLanguages.getEnglishSlug(lng)

        const t = i18next ? i18n.getFixedT(locale) : i18n.t
        const res = t(`UI${seper[i18next]}(slug)${opener[i18next]}lang${closer[i18next]}-Keyboard`, {
            lang: t(`LANG${seper[i18next]}${keyboardLanguages.data[lng].name}`)
        }).replace(/\s/g, '-')
        if (locale === 'de') return res.replace('sch-', 'sche-')
        return res.replace(/&#39;/g, "'") // for hebrew slugs
    },
    // slugify(i18n.t(translatify(keyboardLanguages.getEnglishSlug(lng)))),
    // hebrew-keyboard|english-keyboard|arabic-keyboard
    getAllSlugsForPath: (i18n) =>
        memoize((locale) =>
            Object.keys(keyboardLanguages.data)
                .map((lng) => keyboardLanguages.getSlug(lng, i18n, locale))
                .join('|')
                .replace('(', '[')
                .replace(')', ']')
        )(i18n.language),

    // en => Clavier Angles
    keyToLocaleName: (key, i18n, locale) => {
        locale = locale || i18n.language
        return memoize(
            (key, locale) => {
                const res = titleAdjustements(
                    locale,
                    i18n.t('UI.{Lang} Keyboard', {
                        // i18n.getFixedT(locale)
                        Lang: i18n.t(`LANG.${keyboardLanguages.data[key].name}`) // i18n.getFixedT(locale)
                    })
                )
                if (locale === 'de') return res.replace('sch ', 'sche ')
                return res
            }
            // i18n.t(translatify(keyboardLanguages.data[key].name + ' keyboard'))
        )(key, locale)
    },

    // en => Angles
    keyToLocaleLanguageName: (key, i18n, musa_yashir = false) =>
        memoize((key, locale) => {
            const res = titleAdjustements(locale, i18n.t(`LANG.${keyboardLanguages.data[key].name}`))
            if (musa_yashir && locale === 'de') return res.replace('sch ', 'sche ')
            return res
        })(key, i18n.language),

    // en => Angles (English)
    keyToLocaleLanguageNamePlusNative: (key, i18n) =>
        memoize((key, locale) => {
            let name = keyboardLanguages.keyToLocaleLanguageName(key, i18n)
            if (locale === 'es') name = name.toLowerCase() // for spanish, language names are not in capital when one word
            const native = keyboardLanguages.data[key].name_native
            return name + (native && key !== locale && name !== native ? ' (' + native + ')' : '')
        })(key, i18n.language),

    // en => Clavier Angles (English)
    keyToLocaleNamePlusNative: (key, i18n, spaces = false, locale) => {
        locale = locale || i18n.language
        spaces = spaces ? ' ' : ''
        return memoize((key, locale, spaces) => {
            const name = keyboardLanguages.keyToLocaleName(key, i18n, locale)
            const native = keyboardLanguages.data[key].name_native
            return name + (native && key !== locale && name !== native ? ' (' + spaces + native + spaces + ')' : '')
        })(key, locale, spaces)
    },

    //  // en => Clavier Angles (English)
    //  keyToLocaleNamePlusNativeTitle: key =>
    //     memoize(locale =>
    //       keyboardLanguages.keyToLocaleName(key) + (keyboardLanguages.data[key].title_native ? ' (' + keyboardLanguages.data[key].name_native + ')' : '')
    //     )(i18n.language),

    // => [{key: 'he', value:allSortedLocaleNamePlusNative 'Clavier Hebrew (עברית)'}, ...] (sorted)
    allSortedLocaleNamePlusNative: (i18n, withKeyboard = true) =>
        memoize((locale, withKeyboard) =>
            Object.keys(keyboardLanguages.data)
                .map((key) => ({
                    key,
                    value: withKeyboard
                        ? keyboardLanguages.keyToLocaleNamePlusNative(key, i18n)
                        : keyboardLanguages.keyToLocaleLanguageNamePlusNative(key, i18n)
                }))
                .sort((a, b) => (a.value > b.value || a.value < b.value ? +(a.value > b.value) * 2 - 1 : 0))
        )(i18n.language, withKeyboard),

    // en => clavier-angles
    keyToLocaleSlug: (key, i18n, locale, i18next) => {
        locale = locale || i18n.language
        return memoize((key, locale, i18next) =>
            slugAdjustements(locale, keyboardLanguages.getSlug(key, i18n, locale, i18next))
        )(key, locale, i18next)
    },

    // clavier-angles => en
    slugToKey: (slug, i18n, locale = null, i18next = false) =>
        Object.keys(keyboardLanguages.data).find(
            (key) =>
                keyboardLanguages.keyToLocaleSlug(key, i18n, locale, i18next) &&
                slug &&
                (keyboardLanguages.keyToLocaleSlug(key, i18n, locale, i18next).toLowerCase() === slug.toLowerCase() ||
                    keyboardLanguages.getEnglishSlug(key).toLowerCase() == slug.toLowerCase())
        )

    // [Clavier Angles, Clavier B..., Cla...]
    // getLocaleNamesSorted: () =>
    //   memoize(locale =>
    //     Object.values(keyboardLanguages.data)
    //       .map(lngObj =>
    //         toTitleCase(i18n.t(translatify(lngObj.name + ' keyboard')))
    //       )
    //       .sort()
    //   )(i18n.language),
}

export const keyboardSections = {
    // resolve: {
    //   '': 'freetext',
    //   Translate: 'trans',
    //   'Google Search': 'google',
    //   Wikipedia: 'wiki',
    //   'Send Email': 'email',
    // },
    data: {
        freetext: { slug: '' },
        trans: { slug: 'Translate' },
        google: { slug: 'Google-Search' },
        wiki: { slug: 'Wikipedia' },
        email: { slug: 'Send-Email' }
    },

    // // google => Google Search :
    // keyToName: key => keyboardSections.data[key].Name,
    // // Google Search | google search => google :
    // nameToKey: name => Object.keys(keyboardSections.data).find( key => keyboardSections.keyToName(key) === toTitleCase(name)),
    // // google => Google Recherche
    // keyToLocName: key => toTitleCase( i18n.t( translatify( keyboardSections.keyToName(key) ) ) ),
    // // google recherche => google
    // locNameToKey: locName => Object.keys(keyboardSections.data).find( key => keyboardSections.keyToLocName(key) === locName),

    // google => google-search :
    keyToEnSlug: (key) => keyboardSections.data[key].slug,

    // google => Google Recherche :
    keyToLocSlug: (sectKey, i18n, locale, i18next = false) => {
        // console.log(sectKey, locale, i18n.language, 'ffff')

        locale = locale || i18n.language
        const t = i18next ? i18n.getFixedT(locale) : i18n.t

        return slugAdjustements(
            locale,
            titleAdjustements(
                locale,
                sectKey === 'freetext' || !sectKey
                    ? ''
                    : locale === 'en'
                    ? keyboardSections.keyToEnSlug(sectKey)
                    : t(`UI${seper[i18next]}(slug)` + keyboardSections.keyToEnSlug(sectKey, i18n)) //i18n.getFixedT(locale)
            )
        ).replace(/\s/g, '-')
    },
    //  => google-search|translate|wikipedia| ...

    getAllSlugsForPath: (i18n) =>
        memoize((locale) =>
            Object.keys(keyboardSections.data).reduce((acc, sectKey) =>
                (acc + '|' + keyboardSections.keyToLocSlug(sectKey, i18n, locale)).replace('(', '[').replace(')', ']')
            )
        )(i18n.language),

    // google-recherche => google
    slugToKey: (slug, i18n) =>
        Object.keys(keyboardSections.data).find((key) => {
            return (
                keyboardSections.keyToLocSlug(key, i18n).toLowerCase() ===
                    (typeof slug === 'undefined' ? '' : slug.toLowerCase()) ||
                keyboardSections.keyToEnSlug(key).toLowerCase() ===
                    (typeof slug === 'undefined' ? '' : slug.toLowerCase())
            )
        })

    // getNameForSlugToSectionAll: memoize( () => Object.keys(keyboardSections.data).reduce(
    //       (acc, cur) => (acc[keyboardSections.data[cur].NameForSlug] = cur), {})), // {'Google search' : "google", ...}

    // getLocaleNameToSlugArr: => memoize( locale => Object.keys(keyboardSections.data)
    //     .reduce( (acc,cur) => acc[] (i18n.language ),
    // getSection: (localeName) => Object.keys(keyboardSections.data).find( (key) => keyboardSections.data[key]. ),
    // getLocaleNameToSlugArr: => memoize( locale => Object.keys(keyboardSections.data)
    //                          .reduce( (acc,cur) => acc[] (i18n.language ),
    // getAllSlugsForPath: () => memoize( locale =>
    //       keyboardSections.data.reduce( (acc,cur) => ( acc +'|'+ keyboardSections.getLocaleNameForSlug(translatify(cur.NameForSlug) ) ) ) )
    //       (i18n.language ),  // he => hebrew-keyboard.  i18n.language for cache key.
}

export const sitePath = {
    legacyMapping: {
        1: 'email',
        2: 'freetext',
        3: 'google',
        4: 'wiki',
        16: 'trans'
    },

    slugsToPath: (keyboardSlug = '', sectionSlug = '', parsedSearch, i18n, locale) => {
        locale = locale || i18n.language

        /// // legacy urls ///
        if (parsedSearch && parsedSearch.language && keyboardLanguages.data[parsedSearch.language] !== undefined) {
            const checkedKeyboardSlug = keyboardLanguages.keyToLocaleSlug(parsedSearch.language, i18n)
            if (checkedKeyboardSlug) keyboardSlug = checkedKeyboardSlug
        }

        if (keyboardSlug) {
            if (parsedSearch.sec) {
                const sectKey = sitePath.legacyMapping[parsedSearch.sec]
                if (sectKey) {
                    sectionSlug = keyboardSections.keyToLocSlug(sectKey, i18n)
                }
            }
        }
        /// /

        const url =
            // (locale && locale !== 'en' && locale !== 'fr' && locale !== 'es' ? '/' + locale : '') +
            (keyboardSlug ? '/' + slugAdjustements(locale, keyboardSlug) : '') +
            (sectionSlug ? '/' + slugAdjustements(locale, sectionSlug) : '')
        // (extra ? window.location.search + window.location.hash : "")
        // if changing update condition in useResolveURL!!!!

        return urlAdjustements(locale, url)
    },

    keysToPath: (keyboardKey = '', sectionKey = '', i18n, locale, extra = '', i18next = false) =>
        memoize((keyboardKey, sectionKey, locale, extra, i18next) => {
            locale = locale || i18n.language
            const url =
                // (locale && locale !== 'en' && locale !== 'fr' && locale !== 'es' ? '/' + locale : (keyboardKey ? '' : '/')) +
                (keyboardKey ? '/' + keyboardLanguages.keyToLocaleSlug(keyboardKey, i18n, locale, i18next) : '') +
                (keyboardKey && sectionKey && sectionKey !== 'freetext'
                    ? '/' + keyboardSections.keyToLocSlug(sectionKey, i18n, locale, i18next)
                    : '') +
                (extra || '')
            // (window.location.search || window.location.search ? window.location.search + window.location.hash : '')) //#endregion
            return urlAdjustements(locale, url).replace(/\s/g, '-')
        })(keyboardKey, sectionKey, locale, extra, i18next),

    // with /he/ /fr/ prefix at the begining
    keysToPathWithLocale: (keyboardKey = '', sectionKey = '', i18n, locale, extra = '', i18next = false) => {
        locale = locale || i18n.language
        return (
            (locale !== 'en' && sectionKey && keyboardKey ? '/' + locale : '') +
            sitePath.keysToPath(keyboardKey, sectionKey, i18n, locale, extra, i18next)
        )
    }
}

export const translateSwapMapping = {
    data: { he: 'iw' },
    kb2goog: (lang) => translateSwapMapping.data[lang] || (keyboardLanguages.data[lang] ? lang : ''),
    goog2kb: (lang) => reverseObject(translateSwapMapping.data)[lang] || (keyboardLanguages.data[lang] ? lang : '')
}

export const locales = { ...Locales }

const SiteData = {
    locales,
    keyboardLanguages,
    keyboardSections,
    sitePath,
    translateSwapMapping
}

export default SiteData
