// for ui texts (as oppose to content, only lowercase)
export const slugify = (s) => (s ? s.replace(/ /g, '-').toLowerCase() : '') // lowcase, dash

export const translatify = (s) => (s ? s.replace(/-/g, ' ').toLowerCase() : '') // make ready for tranlate (lowcase, space)

export const capitalize = (s) => {
    // only first char
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const newLineChar = '%0A'

export const extractContent = (s, pref = { oneLine: true }) => {
    var span = document.createElement('span')
    const char = pref.oneLine ? ' ' : newLineChar
    span.innerHTML = s ? s.replace(/(<br[/]*>|%0A)/g, char) : ''
    return span.textContent || span.innerText
}

export const escapedToHtml = (s) => {
    return s.replace(newLineChar, '<br>').replace('%20', ' ').replace(/\n\r?/g, '<br/>')
}

export const escapedToArray = (s) => {
    return s.split(/\n/)
}

// const printCallback = (arr) => console.log.apply(console, arr);

// reverse object of tyoe { key: val, key2: val2} to {val: key, val2: key2}
export const reverseObject = (obj) => {
    return Object.entries(obj).reduce((ret, entry) => {
        const [key, value] = entry
        ret[value] = key
        return ret
    }, {})
}

export function accept_kbLangSlug(locale, kbLangSlug) {
    if (kbLangSlug && locale == 'de') {
        kbLangSlug = kbLangSlug.replace(/sch-[tT]/g, 'sche-T')
    }
    return kbLangSlug
}

export function httpGetAsync(theUrl, callback) {
    var xmlHttp = new XMLHttpRequest()
    xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) callback(xmlHttp.responseText)
    }
    xmlHttp.open('GET', theUrl, true) // true for asynchronous
    xmlHttp.send(null)
}

// monkey patching to prevent pushing same url into history stack
// check in the future for updates:
// https://github.com/ReactTraining/history/issues/470
// https://github.com/ReactTraining/history/pull/558
//

// let lastLocation = window.location
// export const monkeyPatchHistoryChangedUrl = (history) => {
//   history.listen(location => {
//     lastLocation = location
//   })

//   const prevHistoryPush = history.push
//   history.push = (pathname, state = {}) => {
//     if (lastLocation === null ||
//         pathname !== decodeURI(lastLocation.pathname + lastLocation.search + lastLocation.hash)
//         // || JSON.stringify(state) !== JSON.stringify(lastLocation.state)
//     ) {
//       prevHistoryPush(pathname, state)
//     }
//   }
// }
