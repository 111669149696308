import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'

const share_host_prefix = 'https://gate2home.com'
const load = require('load-script')

export const addthisLoad = () => load('//s7.addthis.com/js/300/addthis_widget.js#username=ilanbm')

const AddThisToolbox = React.memo(({ url }) => {
    /* eslint-disable */

    useEffect(() => {
        if (typeof window !== 'undefined' && window.addthis) {
            window.addthis.update('share', 'url', share_host_prefix + url)
            window.addthis.url = share_host_prefix + url
            window.addthis.toolbox('.addthis_toolbox')
            window.addthis.init()
        }
    }, [url])

    return (
        <div className={`addthis_toolbox addthis_default_style addthis_32x32_style`}>
            <style jsx>{`
                .addthis_toolbox {
                    float: right;
                    width: 250px;
                }
            `}</style>
            <a className='addthis_button_preferred_1' />
            <a className='addthis_button_preferred_2' />
            <a className='addthis_button_preferred_3' />
            <a className='addthis_button_preferred_4' />
            <a className='addthis_button_compact' />
            <a className='addthis_counter addthis_bubble_style' />
        </div>
    )

    /* eslint-enable */
})

const useStyles = makeStyles((theme) => ({
    share: {
        borderTop: 'none',
        borderLeft: '2px dotted #2c4a80',
        paddingLeft: '22px',
        marginLeft: '8px',
        borderRadius: '0',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            borderLeft: 'none ',
            borderTop: '2px dotted #2c4a80',
            paddingLeft: '13px',
            marginLeft: '0',
            paddingTop: '17px'
        }
    },
    share_a: {
        color: '#bfbfbf !important',
        textDecoration: 'none',
        textTransform: 'none',
        '&:hover': {
            color: '#ffffff !important'
        }
    }
}))

const AddThisShareMenuButton = React.memo(({ children }) => {
    /* eslint-disable */
    const classes = useStyles()

    return (
        <Button className={classes.share} onClick={addthisLoad} onMouseOver={addthisLoad}>
            <style jsx>{`
                .at-icon-wrapper {
                    display: none;
                }
            `}</style>
            <a href='#' className={`addthis_button_expanded ${classes.share_a}`}>
                <span className='at-icon-wrapper'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 32 32'
                        version='1.1'
                        role='img'
                        aria-labelledby='at-svg-addthis-1'
                        title='Expanded'
                        alt='Expanded'
                        className='at-icon at-icon-addthis'
                    >
                        <g>
                            <path d='M18 14V8h-4v6H8v4h6v6h4v-6h6v-4h-6z' fillRule='evenodd' />
                        </g>
                    </svg>
                </span>
                {children}
            </a>
        </Button>
    )

    /* eslint-enable */
})

AddThisToolbox.displayName = 'AddThisToolbox'
AddThisShareMenuButton.displayName = 'AddThisShareMenuButton'

export { AddThisToolbox, AddThisShareMenuButton }
