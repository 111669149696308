import { Contexter, storageReducer } from './Contexter'

const initialState = {}

const reducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case 'update-value':
            return { ...state, [payload.key]: payload.value || '' }
        default:
            return state
    }
}

const { Provider, useStateDispatch } = Contexter('Textbox', [reducer, storageReducer], initialState)

export { useStateDispatch as useTextboxContext }
export { Provider as TextboxProvider }
