import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

export default function useGate2HomeRouter() {
    const router = useRouter()
    // let { kbLangSlug } = router.query
    // kbLangSlug = accept_kbLangSlug(router.locale, kbLangSlug)

    // const t = useTranslations()
    const t = useCallback(useTranslations(), [router.locale])

    const i18n = useMemo(() => {
        return {
            language: router.locale,
            t: t,
            dir: () => (['he', 'ar', 'fa', 'ur'].includes(router.locale) ? 'rtl' : 'ltr')
        }
    }, [router.locale, t]) // missing t in purpose, it is new on every useTranslation run

    // const kbLangKey = useMemo(() => keyboardLanguages.slugToKey(kbLangSlug, i18n), [i18n, kbLangSlug])

    // const sectionKey = useMemo(
    //     () =>
    //         kbLangKey ? keyboardSections.slugToKey(sectionSlug, i18n) : sectionSlug || kbLangSlug ? undefined : 'home',
    //     [i18n, kbLangKey, kbLangSlug, sectionSlug]
    // )

    return { i18n, t, router }
}
